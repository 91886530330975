<template>
  <button
    teste2e-selector="open-custom-project-form"
    class="fr-mt-5v fr-bg--purple--lightness fr-btn-bg--purple--light fr-py-28v fr-radius-a--1v fr-p-md-2v fr-py-md-4v fr-text--purple fr-text--bold fr-text-xl fr-btn-fullwidth fr-btn-align-center"
  >
    <img
      src="/images/cross.svg"
      height="50px"
      alt="image / autre projet"
    />
    <div>J'ai un autre projet</div>
  </button>
</template>
<script lang="ts" setup></script>
