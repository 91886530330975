<template>
  <div
    teste2e-select="callback-contact-form"
    class="fr-col-12 fr-col-md-8"
  >
    <!-- MESSAGE IF ERROR-->
    <TeeError
      v-if="!hasValidResponse"
      teste2e-selector="error-callback-contact-form"
      :mailto="getMailTo()"
      :email="Contact.email"
    >
      <h4 class="fr-text--deep-red fr-text--lg fr-m-0">
        {{ Translation.t(`form.notSent`) }}
      </h4>
    </TeeError>

    <!-- MESSAGE IF 200 -->
    <div
      v-if="hasValidResponse"
      teste2e-selector="success-callback-contact-form"
      class="fr-text-center"
    >
      <p class="fr-text--blue-france">
        <span
          class="fr-icon-checkbox-circle-fill fr-icon--lg"
          aria-hidden="true"
        />
      </p>
      <h3 class="fr-text--blue-france">
        {{ Translation.t(`form.sent`) }}
      </h3>
      <h6 class="fr-mt-15v fr-mb-3v">
        {{ Translation.t('form.nowWhat') }}
      </h6>
      <slot name="phoneContact" />
    </div>
  </div>
</template>
<script setup lang="ts">
import Translation from '@/utils/translation'
import Contact from '@/utils/contact'
import { FormDataType, type ReqResp } from '@/types'

interface Props {
  form: FormDataType
  requestResponse: ReqResp | undefined
  errorEmailSubject: string
}
const props = defineProps<Props>()

const hasValidResponse = computed(() => {
  return !props.requestResponse || props.requestResponse.status === 200 || props.requestResponse.status === 201
})

const getMailTo = (): string => {
  if (props.form) {
    const needsValue = props.form.needs.value ? props.form.needs.value : ''
    const nameValue = props.form.name.value ? props.form.name.value : ''
    const surnameValue = props.form.surname.value ? props.form.surname.value : ''
    const telValue = props.form.tel.value ? props.form.tel.value : ''
    const siretValue = props.form.siret.value ? props.form.siret.value : ''
    return Contact.getMailtoUrl(
      props.errorEmailSubject,
      `${needsValue}

  ${nameValue} ${surnameValue}
  ${telValue}
  SIRET : ${siretValue}`
    )
  }

  return ''
}
</script>
